<template>
  <v-container
    id="scroll-target"
    grid-list-md
    pa-0
    relative
    class="attendence_view"
  >
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="page-container-card primary--border" outlined>
          <v-card-title class="title">
            Student Identity Card
            <v-spacer></v-spacer>

            <download-button
              v-if="form.items.data.length"
              icon="print"
              @action="printBulk()"
              >PRINT BULK</download-button
            >
          </v-card-title>
          <v-card outlined>
            <v-card-title class="pa-0">
              <grade-section @onSectionChange="get"></grade-section>
              <v-flex xs2>
                <v-calendar-field
                  id-val="valid_date"
                  v-model="valid_date"
                  label="Valid Date"
                >
                </v-calendar-field>
              </v-flex>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            footer-props.items-per-page-options="rowsPerPageItems"
            :server-items-length="form.items.meta.total"
            style="margin-top: 15px"
            :loading="form.loading"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  <v-list-item-avatar>
                    <img :src="item.profile_image" />
                  </v-list-item-avatar>
                </td>
                <td class="text-xs-left">
                  <strong v-if="pagination.sortBy === 'name'">{{
                    item.name
                  }}</strong>
                  <span v-else>{{ item.name }}</span>
                </td>
                <td class="text-xs-left">{{ item.personal.dob }}</td>
                <td class="text-xs-left">
                  {{ item.personal.gender | capitalize }}
                </td>
                <td class="text-xs-left">
                  {{ item.grade }} <sup>{{ item.section }}</sup>
                </td>
                <td class="text-xs-center">
                  {{ item.roll }}
                </td>
                <td class="text-right">
                  <!--<v-btn small icon color="primary" class="pa-0 ma-1"-->
                  <!--@click="printSingle(item.enroll_id)">-->
                  <!--<v-icon dark>print</v-icon>-->
                  <!--</v-btn>-->
                  <add-button
                    @action="printSingle(item.enroll_id)"
                    :permission="'section-read'"
                    icon="print"
                  >
                    Print
                  </add-button>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { cleanObj, validateDate } from "../../../../library/helpers";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();

export default {
  data: () => ({
    student_code: "",
    form: new Form({}, "/api/search-student"),
    search: "",
    batches: [],
    rowsPerPageItems: [5, 10, 25, 50, 75],
    pagination: {
      rowsPerPage: 25,
    },
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    loading: true,
    headers: [
      { text: "#", align: "left", value: "roll", width: 10, sortable: false },
      {
        text: "Photo",
        align: "left",
        value: "roll",
        width: 50,
        sortable: false,
      },
      { text: "Name", align: "left", value: "name", width: 160 },
      {
        text: "Date of birth",
        align: "left",
        value: "dob",
        sortable: false,
        width: 100,
      },
      {
        text: "Gender",
        align: "left",
        value: "gender",
        sortable: false,
        width: 100,
      },
      {
        text: "Grade",
        align: "left",
        value: "grade",
        sortable: false,
        width: 100,
      },
      {
        text: "Roll",
        align: "center",
        value: "roll",
        width: "25",
        sortable: true,
      },
      {
        text: "Action",
        align: "right",
        value: "roll",
        sortable: false,
        width: 150,
      },
    ],
    // section: '',
    // grade: '',
    // batchId: '',
    // sections: [],
    // grades: [],
    filter: false,
    valid_date: nd.format("YYYY") + "-12-30",
    // studentRollNumbers: []
  }),
  watch: {
    pagination: function() {
      this.get();
    },
    batch: function() {
      this.get();
    },
    search(val) {},
  },
  computed: {
    ...mapState(["batch", "grade"]),
  },
  mounted() {},
  methods: {
    queryString() {
      let json = cleanObj(this.pagination);

      return (
        "?" +
        Object.keys(json)
          .map((key) => {
            if (typeof json[key] == "object") {
              let vals = [...json[key]];
              if (vals.length)
                return (
                  encodeURIComponent(key) +
                  "=" +
                  encodeURIComponent(vals.join(","))
                );
              else return null;
            }
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
            );
          })
          .join("&") +
        "&sectionId=" +
        this.grade.section.selected.toString() +
        "&search=" +
        this.search.toString().replace(" ", "-") +
        "&gradeId=" +
        this.grade.grade.selected.toString()
      );
    },
    get(params) {
      this.loading = true;
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      if (this.grade.section.selected) {
        this.form.get(null, query).then(({ data }) => {
          let $this = this;
          this.form.items.data = data.data.map(function(item) {
            item.edit_roll = false;
            return item;
          });
          this.pagination.totalItems = data.meta.total;
          this.updateState = !!data.update;
          this.loading = false;
        });
      }
    },
    clearFilter() {
      this.grade.grade.selected = "";
      this.grade.section.selected = "";
      this.form.items.data = [];
      this.get();
    },
    printBulk() {
      this.$rest.get("/api/print/student-id-card").then(({ data }) => {
        let url =
          data.data.download_url +
          "?gradeId=" +
          this.grade.grade.selected +
          "&sectionId=" +
          this.grade.section.selected +
          "&validTill=" +
          this.valid_date.dateForm();
        window.open(url);
      });
    },

    //update
    printSingle(enrollId) {
      this.$rest.get("/api/print/student-id-card").then(({ data }) => {
        let url =
          data.data.download_url +
          "?gradeId=" +
          this.grade.grade.selected +
          "&sectionId=" +
          this.grade.section.selected +
          "&validTill=" +
          this.valid_date.dateForm() +
          "&enrollId=" +
          enrollId;
        window.open(url);
      });
    },
  },
};
</script>
<style scoped>
.inputLabelabsent {
  background: #e22712;
  color: white;
}

.inputLabelLeave {
  background: #29b6f6;
  color: white;
}

.inputLabelLate {
  background: #ffb74d;
  color: black;
}

.inputRadio {
  display: none;
}
</style>
